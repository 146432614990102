import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../components/layout"
import {Box, Typography, Container, Grid} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Idea from "../../static/Imagen/idea.png"
import Engrane from "../../static/Imagen/engrane.png"
import ManoAzul from "../../static/Imagen/manoazul.png"

const useStyles = makeStyles(theme => ({
    root: {},
    containerPart: {
        [theme.breakpoints.down("md")]: {
            marginTop: "6em"
        },
        marginTop: "10em"
    },
    linksStyles: {
        textDecoration: "none",
        color: theme.palette.common.black,
        fontWeight: 500,
        "&:hover": {
            color: "#291C64"
        }
    },
    paper: {
        maxWidth: 900,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
        textAlign: "justify"
    },
    media: {
        height: 170
    }
}))

export default function CflDiferent() {
    const classes = useStyles()
    const message = `Hemos hecho sencillo algo complejo. Nuestra plataforma permite que los inversores no se tengan que molestar en seleccionar y construir un portafolio diversificado de financiamientos, pues CFL ha desarrollado un sistema propio de diversificación (por favor, para mayor detalle vea nuestra sección de diversificación de inversiones). 
    Nuestros inversores solo tiene que preocuparse de seleccionar el producto de inversión en función de la disponibilidad deseada. Además, nuestro sistema de re-inversión automática permite reinvertir de forma autónoma los pagos de los solicitantes. 
     `
    const message1 = `Hemos desarrollado un mercado secundario interno para ofrecer así mayor flexibilidad a sus inversiones. De esta manera, cuando los inversores solicitan un retiro de forma automática nuestro mercado secundario vende sus financiamientos o partes de financiamientos a otros inversores todo ello basado en un sistema de colas.    `
    const message2 = `CFL no aplica ninguna comisión adicional por estos servicios. Todos los servicios proporcionados están incluidos en los retornos esperados de nuestros productos. `

    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>CFL</title>
            </Helmet>
            <Container className={classes.containerPart}>
                <Box maxWidth="80%" m="auto">
                    <Box mt={4} py={2}>
                        <Typography variant="h1" component="h1" align="center" gutterBottom>
                            <b>¿Qué nos hace diferentes?</b>
                        </Typography>
                    </Box>
                    <Box>
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <Grid container wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <img src={Idea} alt='img'
                                        />
                                    </Grid>
                                    <Grid container xs alignItems="center" p={2}>
                                        <Typography textAlign="center">{message}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper className={classes.paper}>
                                <Grid container wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <img src={Engrane} alt='img'
                                        />
                                    </Grid>
                                    <Grid container xs alignItems="center" p={2}>
                                        <Typography textAlign="center">{message1}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper className={classes.paper}>
                                <Grid container wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <img src={ManoAzul} alt='img'
                                        />
                                    </Grid>
                                    <Grid container xs alignItems="center" p={2}>
                                        <Typography>{message2}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </Box>
                </Box>
            </Container>
        </Layout>
    )
}
